import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

const Separator = ({ className }) => {
  return <span className={classNames('separator', className)}>·</span>
}

Separator.propTypes = {
  className: PropTypes.string,
}

export default Separator
