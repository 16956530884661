import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import useTabbingFocus from '../../../hooks/useTabbingFocusListener'

import './styles.scss'

const BlogLandingPageTemplate = ({ className, header, footer, children }) => {
  if (typeof window !== 'undefined') {
    useTabbingFocus(window)
  }

  const blogPageClasses = classNames('blog-landing-page__main', className)
  return (
    <>
      {header}
      <main className="blog-landing-page">
        <div className={blogPageClasses}>
          <div className="blog-landing-page__content">{children}</div>
        </div>
      </main>
      {footer}
    </>
  )
}

BlogLandingPageTemplate.propTypes = {
  header: PropTypes.element.isRequired,
  footer: PropTypes.element.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
}

export default BlogLandingPageTemplate
