import React from 'react'
import PropTypes from 'prop-types'
import Heading from '@afs/components/Heading'

import PopularArticleCard from '../../molecules/PopularArticleCard'

import './styles.scss'

const PopularArticlesList = ({ className, articles }) => {
  return (
    <div className={className} data-testid="popular-articles-list">
      <Heading className="popular-articles-list__heading" level={4}>
        Popular articles
      </Heading>
      {articles.map((article) => (
        <PopularArticleCard
          className="popular-articles__article"
          key={article.url}
          article={article}
        />
      ))}
    </div>
  )
}

PopularArticlesList.propTypes = {
  articles: PropTypes.array.isRequired,
  className: PropTypes.string,
}

export default PopularArticlesList
