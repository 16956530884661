import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Heading from '@afs/components/Heading'

import {
  getLandlordBlogArticles,
  getStudentBlogArticles,
} from '../../../api/blogApi'
import useMedia from '../../../hooks/useMedia'
import { useCountryContext } from '../../../contexts/countryContext'

import ArticleCard from '../../molecules/ArticleCard'
import BlogLinkCard from '../../molecules/BlogLinkCard'
import SpinnerWithText from '../../molecules/SpinnerWithText'
import Footer from '../../organisms/Footer'
import Header from '../../organisms/Header'
import PopularArticlesList from '../../organisms/PopularArticlesList'
import BlogLandingPageTemplate from '../../templates/BlogLandingPageTemplate'
import MetaTags from '../../atoms/MetaTags'

import ThumbsUp from './thumbs-up.svg'

import './styles.scss'

export const BlogLandingPageContainerCompositionRoot = ({
  viewModel,
  history,
}) => {
  return (
    <BlogLandingPageContainer
      viewModel={viewModel}
      history={history}
      getLandlordBlogArticles={getLandlordBlogArticles}
      getStudentBlogArticles={getStudentBlogArticles}
    />
  )
}

export const BlogLandingPageContainer = ({
  viewModel,
  history,
  getLandlordBlogArticles,
  getStudentBlogArticles,
}) => {
  useEffect(() => {
    if (viewModel.error) {
      history.push('/error')
    }
  }, [])

  const getArticles = viewModel.landlordBlog
    ? getLandlordBlogArticles
    : getStudentBlogArticles

  return (
    <BlogLandingPage
      viewModel={viewModel}
      history={history}
      getArticles={getArticles}
      isLargeScreen={useMedia(['(min-width: 961px)'], [true], false)}
    />
  )
}

export const BlogLandingPage = ({
  viewModel,
  history,
  getArticles,
  isLargeScreen,
}) => {
  const { isInternational } = useCountryContext()

  const pageSize = 4
  const blogsWrapperRef = React.createRef()

  const { blogData, landlordBlog, urlPath, absoluteUrl } = viewModel
  const { metaData, heading, popularArticles } = blogData

  const [articles, setArticles] = useState(blogData.articles)
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)
  const [noMoreArticlesRemaining, setNoMoreArticlesRemaining] = useState(false)

  const showPopularArticles = popularArticles.length >= 1

  const handleLoadMoreResultsOnScroll = () => {
    if (noMoreArticlesRemaining) return

    const currentScrollPosition = window.pageYOffset

    if (blogsWrapperRef.current === null) return

    const bottomOfBlogsWrapper =
      blogsWrapperRef.current.offsetHeight +
      blogsWrapperRef.current.offsetTop -
      1000

    if (currentScrollPosition >= bottomOfBlogsWrapper) {
      setShowLoadingSpinner(true)

      getArticles(articles.length, pageSize).then((response) => {
        if (response.success && response.articles.length === 0) {
          setNoMoreArticlesRemaining(true)
        }

        if (response.success) {
          const newArticles = [...articles, ...response.articles]
          history.push(`${urlPath}?articles=${newArticles.length}`, null, {
            shallow: true,
            scroll: false,
          })

          setShowLoadingSpinner(false)
          setArticles(newArticles)
        }
      })
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleLoadMoreResultsOnScroll)
    }
    return () =>
      window.removeEventListener('scroll', handleLoadMoreResultsOnScroll)
  })

  return (
    <BlogLandingPageTemplate
      header={<Header className="blog-landing-page__header" showNavigation />}
      footer={<Footer />}
    >
      <MetaTags
        title={metaData.title}
        description={metaData.description}
        absoluteUrl={absoluteUrl}
      />

      <div className="blog-landing-page__heading">
        <Heading className="blog-landing-page__title" level={1} h1Large>
          {heading.title}
        </Heading>
        <p className="blog-landing-page__sub-title">{heading.subtitle}</p>
      </div>

      <div
        ref={blogsWrapperRef}
        className="blog-landing-page__articles-wrapper"
        data-testid="blog-landing-page-articles-wrapper"
      >
        <div className="blog-landing-page__left-column">
          {articles.map((article) => (
            <ArticleCard key={article.url} article={article} />
          ))}
          {showLoadingSpinner && (
            <SpinnerWithText text="Loading more articles..." />
          )}
          {noMoreArticlesRemaining && (
            <div className="blog-landing-page__no-more-articles">
              <ThumbsUp class="blog-landing-page__no-more-articles-icon" />
              <span>{`You're all caught up`}</span>
            </div>
          )}
        </div>

        {isLargeScreen && (
          <div className="blog-landing-page__right-column">
            {showPopularArticles && (
              <PopularArticlesList
                className="blog-landing-page__popular-articles"
                articles={popularArticles}
              />
            )}
            {!isInternational && <BlogLinkCard landlordBlog={landlordBlog} />}
          </div>
        )}
      </div>
    </BlogLandingPageTemplate>
  )
}

BlogLandingPageContainerCompositionRoot.propTypes = {
  viewModel: PropTypes.object,
  history: PropTypes.object.isRequired,
}

BlogLandingPageContainer.propTypes = {
  viewModel: PropTypes.object,
  history: PropTypes.object.isRequired,
  getLandlordBlogArticles: PropTypes.func.isRequired,
  getStudentBlogArticles: PropTypes.func.isRequired,
}

BlogLandingPage.propTypes = {
  viewModel: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getArticles: PropTypes.func,
  isLargeScreen: PropTypes.bool,
}

export default BlogLandingPageContainerCompositionRoot
