import React from 'react'
import PropTypes from 'prop-types'

import Spinner from '@afs/components/Spinner'

import './styles.scss'

const SpinnerWithText = ({ text }) => {
  return (
    <>
      <div
        className="spinner-with-text__wrapper"
        data-testid="spinner-with-text"
      >
        <Spinner className="spinner-with-text__spinner" />
        <p className="spinner-with-text__text">{text}</p>
      </div>
    </>
  )
}

SpinnerWithText.propTypes = {
  text: PropTypes.string.isRequired,
}

export default SpinnerWithText
