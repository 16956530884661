import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Heading from '@afs/components/Heading'

import config from '../../../config'

import Picture from '../../atoms/Picture'
import Link from '../../atoms/Link'

import './styles.scss'

const PopularArticleCard = ({ className, article }) => {
  const { title, image, readTime, url, hasSlug, slugifiedUrl } = article

  const articleUrl = hasSlug ? slugifiedUrl : url

  const imageApiUrl = config.imgApiUrl
  const fallbackImageSource = `${imageApiUrl}w=136/${image}`
  const popularArticleClasses = classNames('popular-article-card', className)

  const source = [
    {
      id: 'any',
      srcSet: `${imageApiUrl}w=136/${image} 1x, ${imageApiUrl}w=136,dpr=2/${image} 2x`,
      media: '(min-width: 1px)',
    },
  ]

  return (
    <Link
      className={popularArticleClasses}
      to={articleUrl}
      data-testid="popular-article-card"
    >
      <div className="popular-article-card__picture-wrapper">
        <Picture
          className="popular-article-card__picture"
          sources={source}
          src={fallbackImageSource}
          alt={title}
          lazyload
        />
      </div>

      <div className="popular-article-card__text-wrapper">
        <Heading className="popular-article-card__title" level={6}>
          {title}
        </Heading>
        <p className="popular-article-card__read-time">{readTime}</p>
      </div>
    </Link>
  )
}

PopularArticleCard.propTypes = {
  article: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default PopularArticleCard
