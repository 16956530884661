import React from 'react'
import PropTypes from 'prop-types'
import Heading from '@afs/components/Heading'

import Link from '../../atoms/Link'

import ChevronIcon from '../../../svgs/icons/chevron.svg'

import './styles.scss'
import { useCountryContext } from '../../../contexts/countryContext'

const BlogLinkCard = ({ landlordBlog }) => {
  const { linkBase } = useCountryContext()

  const landlordOrStudent = landlordBlog ? 'student' : 'landlord'

  const text = landlordBlog
    ? 'Read our student blog for advice and information from our student ambassadors.'
    : 'Stay up to date with the latest landlord information on our landlord blog.'

  const link = landlordBlog
    ? `${linkBase}/student-blog`
    : `${linkBase}/student-landlord-guides`

  return (
    <Link
      className="blog-link-card__wrapper-link"
      to={link}
      data-testid="blog-link-card"
    >
      <div className="blog-link-card__wrapper">
        <Heading className="blog-link-card__title" level={4}>
          {`Are you a ${landlordOrStudent}?`}
        </Heading>
        <p className="blog-link-card__text">{text}</p>

        <div className="blog-link-card__link">
          <span>{`Read the ${landlordOrStudent} blog`}</span>
          <ChevronIcon className="blog-link-card__link-icon" />
        </div>
      </div>
    </Link>
  )
}

BlogLinkCard.propTypes = {
  landlordBlog: PropTypes.bool.isRequired,
}

export default BlogLinkCard
