import { performApiGet } from './apiBase'

export const getStudentBlogArticleById = async (id, country = 'gb') => {
  try {
    const response = await performApiGet(
      `/api/blogs/student/article/${id}?countryCode=${country}`
    )

    return {
      success: response.status === 200,
      blogData: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getLandlordBlogArticleById = async (id, country = 'gb') => {
  try {
    const response = await performApiGet(
      `/api/blogs/landlord/article/${id}?countryCode=${country}`
    )

    return {
      success: response.status === 200,
      blogData: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getStudentBlogArticles = async (
  indexOfFirstArticle,
  numberOfArticles,
  country = 'gb'
) => {
  try {
    const response = await performApiGet(
      `/api/blogs/student/latest/${indexOfFirstArticle}/${numberOfArticles}?countryCode=${country}`
    )

    return {
      success: response.status === 200,
      articles: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getLandlordBlogArticles = async (
  indexOfFirstArticle,
  numberOfArticles,
  country = 'gb'
) => {
  try {
    const response = await performApiGet(
      `/api/blogs/landlord/latest/${indexOfFirstArticle}/${numberOfArticles}?countryCode=${country}`
    )

    return {
      success: response.status === 200,
      articles: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getStudentBlogPopularArticles = async (number, country = 'gb') => {
  try {
    const response = await performApiGet(
      `/api/blogs/student/popular/${number}?countryCode=${country}`
    )

    return {
      success: response.status === 200,
      articles: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getLandlordBlogPopularArticles = async (
  number,
  country = 'gb'
) => {
  try {
    const response = await performApiGet(
      `/api/blogs/landlord/popular/${number}?countryCode=${country}`
    )

    return {
      success: response.status === 200,
      articles: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}
