export const truncateStringInTheMiddle = (fullStr, strLen, ellipsis = '…') => {
  if (fullStr.length <= strLen) return fullStr

  const charsToShow = strLen - ellipsis.length
  const frontChars = Math.ceil(charsToShow / 2)
  const backChars = Math.floor(charsToShow / 2)

  return (
    fullStr.substr(0, frontChars) +
    ellipsis +
    fullStr.substr(fullStr.length - backChars)
  )
}

export const truncateStringByCharacters = (
  stringToShorten,
  maxLength,
  ellipsis = '…'
) => {
  const dontTruncateString = stringToShorten.length <= maxLength
  const indexOfLastSpaceCharacter = stringToShorten.lastIndexOf(' ', maxLength)
  const nonLetterCharacters = ['.', ',', '?', '!', ':', ';', '-', '/']
  const stringTerminatesWithANonLetterCharacter = nonLetterCharacters.includes(
    stringToShorten[indexOfLastSpaceCharacter - 1]
  )

  if (dontTruncateString) return stringToShorten

  if (stringTerminatesWithANonLetterCharacter)
    return `${stringToShorten.substr(
      0,
      stringToShorten.lastIndexOf(' ', maxLength) - 1
    )}${ellipsis}`

  return `${stringToShorten.substr(
    0,
    stringToShorten.lastIndexOf(' ', maxLength)
  )}${ellipsis}`
}

export const truncateStringByWords = (
  stringToShorten,
  maxWords,
  ellipsis = '…'
) => {
  const wordsInString = stringToShorten.split(' ')
  const wordCount = wordsInString.length

  if (wordCount <= maxWords) return stringToShorten

  return `${wordsInString.splice(0, maxWords).join(' ')}${ellipsis}`
}

export const truncateStringByNewline = (
  stringToShorten,
  maxLength,
  ellipsis = '…'
) => {
  const stringContainsAnEmptyNewline = stringToShorten.includes('\n\n')
  const indexOfFirstEmptyNewline = stringToShorten.indexOf('\n\n')
  const indexOfLastReturnCharacter = stringToShorten.lastIndexOf(
    '\r',
    indexOfFirstEmptyNewline
  )
  const stringUpToFirstNewline = stringToShorten.slice(
    0,
    indexOfFirstEmptyNewline
  )

  const shortenByNewline =
    stringContainsAnEmptyNewline && stringUpToFirstNewline.length <= maxLength
  const nonLetterCharacters = ['.', ',', '?', '!', ':', ';', '-', '/']
  const stringTerminatesWithANonLetterCharacter = nonLetterCharacters.includes(
    stringToShorten[indexOfLastReturnCharacter - 1]
  )

  if (shortenByNewline && stringTerminatesWithANonLetterCharacter) {
    return `${stringToShorten.slice(
      0,
      indexOfLastReturnCharacter - 1
    )}${ellipsis}`
  }
  if (shortenByNewline) {
    return `${stringToShorten.slice(0, indexOfFirstEmptyNewline)}${ellipsis}`
  }

  return truncateStringByCharacters(stringToShorten, maxLength)
}
