import React from 'react'
import PropTypes from 'prop-types'
import Heading from '@afs/components/Heading'

import config from '../../../config'
import { truncateStringByCharacters } from '../../../utils/truncate'
import useMedia from '../../../hooks/useMedia'

import Separator from '../../atoms/Separator'
import Picture from '../../atoms/Picture'
import Link from '../../atoms/Link'

import './styles.scss'

const ArticleCard = ({ article }) => {
  const {
    title,
    image,
    readTime,
    date,
    description,
    url,
    hasSlug,
    slugifiedUrl,
  } = article

  const isMobile = useMedia(['(max-width: 660px)'], [true], false)

  const truncatedDescriptionDesktop = truncateStringByCharacters(
    description,
    160
  )
  const truncatedDescriptionMobile = truncateStringByCharacters(description, 90)

  const fallbackImageSource = `${config.imgApiUrl}w=712,h=400,fit=cover/${image}`

  const articleUrl = hasSlug ? slugifiedUrl : url

  const sources = [
    {
      id: 'desktop',
      srcSet: `${config.imgApiUrl}w=712,h=400,fit=cover/${image} 1x, ${config.imgApiUrl}w=712,h=400,fit=cover,dpr=2/${image} 2x`,
      media: '(min-width: 481px)',
    },
    {
      id: 'mobile',
      srcSet: `${config.imgApiUrl}w=448,h=252,fit=cover/${image} 1x, ${config.imgApiUrl}w=448,h=252,fit=cover,dpr=2/${image} 2x,`,
      media: '(max-width: 480px)',
    },
  ]

  return (
    <Link
      className="article-card__link"
      to={`${config.baseUrl}${articleUrl}`}
      data-testid="article-card"
    >
      <div className="article-card__wrapper">
        <Picture
          className="article-card__picture"
          sources={sources}
          src={fallbackImageSource}
          alt={title}
          optimiseLcp
        />

        <Heading className="article-card__title" level={2}>
          {title}
        </Heading>
        <p className="article-card__description">
          {isMobile ? truncatedDescriptionMobile : truncatedDescriptionDesktop}
        </p>
        <span className="article-card__read-time">{readTime}</span>
        <Separator className="article-card__seperator" />
        <span className="article-card__date">{date}</span>
      </div>
    </Link>
  )
}

ArticleCard.propTypes = {
  article: PropTypes.object.isRequired,
}

export default ArticleCard
